import React from 'react';
import PropTypes from 'prop-types';
import {Trans, t} from '@lingui/macro';
import FormModal from '../../../fetch/FormModal';
import Tooltip from '../../../general/Tooltip';
import generalNoParameterFormPC from '../../../fetch/generalNoParameterFormPC';
import { createFetchReportRemove } from '../../../../backend/apiCalls';
import generalFailedPC from '../../../fetch/generalFailedPC';
import { Button, Icon } from 'antd';

/**
 * @fero
 */

class RemoveReportButton extends React.PureComponent {
    static propTypes = {
        size: PropTypes.string,
        disabled: PropTypes.bool,
        onFinish: PropTypes.func.isRequired,
        reportId: PropTypes.number.isRequired,
    };

    static defaultProps = {
        disabled: false
    };

    render() {
        const {size, reportId, onFinish, disabled} = this.props;
        return <FormModal
            disabled={disabled}
            openNode={
                <Tooltip title={<Trans>Odstránenie reportu</Trans>}>
                    <Button disabled={disabled} size={size} type="danger">
                        <Icon type="delete"/>
                    {size != 'small' ?
                        <span className="ml-2">
                            <Trans>Odstrániť report</Trans>
                        </span> : 
                        null
                    }
                    </Button>
                </Tooltip>
            }
            values={{}}
            onFinishSuccessful={onFinish}
            title={<Trans>Odstránenie reportu</Trans>}
            Form={generalNoParameterFormPC(
                <Trans>Chcete odstrániť report?</Trans>,
                createFetchReportRemove(reportId),
            )}
            Response={null}
            Failed={generalFailedPC(t`Nepodarilo sa odstrániť report.`)}
        />;
    }
}

export default RemoveReportButton;